import {getData} from "../api/get-data";

export const getAuthPass = (login, resolveHandler, rejectHanlder) => {
  getData({
    [`get_auth_pass`]: {
      [`auth_login`]: login,
    }
  })
  .then((response) => {
    if (response.result === `OK`) {
      resolveHandler(response);
    }

    if (response.result === `ERROR`) {
      rejectHanlder(response.error);
    }
  })
  .catch(() => {
    rejectHanlder();
  });
};
