import {getData} from "../api/get-data";

export const setCity = (city) => {
  if (!city.cityId) {
    return;
  }
  getData({
    [`set_cookie`]: {
      [`name`]: `shop_id`,
      [`value`]: city.cityId,
      [`exdays`]: 7,
    }
  })
  .then(() => {
    if (window.location.hostname === `dev.eto1.ru`) {
      document.location.reload();
    } else {
      document.location.href = `${window.location.protocol}//eto1.ru${window.location.pathname}${window.location.search}${window.location.hash}`;
    }
  });
};
