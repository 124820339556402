import {getData} from "./api/get-data";
import CitiesComponent from "./components/cities-component";
import ContactComponent from "./components/contact-component";
import {parseCitiesData} from "./data/parse-cities-data";
import {onEscPressModalHandler} from "./on-esc-press-modal-handler";
import {getPage} from "./require/get-page";
import {getAlphabetCities} from "./utils/get-alphabet-cities";
import {render, RenderPosition} from "./utils/render";

const contactB2CElement = document.querySelector(`.franchise-page__B2CContent--right`);

const renderContactContent = (container, data) => {
  container.innerHTML = ``;
  const contactComponent = new ContactComponent({data});

  render(container, contactComponent.getElement(), RenderPosition.BEFOREEND);

  window.ymaps.ready(init);
  let x;
  let y;
  let myMap;
  let myPlacemark;
  x = data.x;
  y = data.y;
  function init() {
    myMap = new window.ymaps.Map(`YMapsID`, {
      center: [x, y],
      zoom: 16
    });
    myPlacemark = new window.ymaps.Placemark([x, y],
        {
          hintContent: `Пункт выдачи в ${data.town_p}`,
          balloonContent: `Пункт выдачи <b>${data.domain}</b> в ${data.town_p}<br><b>Адрес:</b> ${data.addr}<br><b>Телефон:</b> ${data.phone_full}<br><b>Режим работы:</b><br>${data.worktime}`
        },
        {
          iconLayout: `default#image`,
          iconImageHref: `img/mapicon/${data.domain}.png`,
          iconImageSize: [30, 60],
          iconImageOffset: [-15, -55]
        }
    );
    myMap.geoObjects.add(myPlacemark);
  }
};

const renderCities = (container, cities) => {
  const alphabetCities = getAlphabetCities(cities);

  alphabetCities.forEach((alphabetCity) => {
    const component = new CitiesComponent({alphabetCity});
    render(container, component.getElement(), RenderPosition.BEFOREEND);
    component.getCityButtonClickHandler((cityId) => {
      contactB2CElement.dataset.loading = true;

      Array.from(container.querySelectorAll(`.cities__cityButton`))
        .filter((item) => item.dataset.active === `true` && item.dataset.cityId !== cityId)
        .forEach((item) => {
          item.dataset.active = `false`;
        });

      getPage(cityId, (data) => {
        renderContactContent(contactB2CElement.querySelector(`div`), data);
        contactB2CElement.dataset.loading = false;
      });
    });
  });
};

export const initFrPage = () => {
  const callbackButton = document.querySelector(`.franchise-page__callbackButton`);
  const callbackModalElement = document.querySelector(`.js--callbackModal`);

  if (callbackButton) {
    callbackButton.addEventListener(`click`, () => {
      callbackModalElement.classList.add(`modal--show`);
      callbackModalElement.dataset.mode = `form`;
      document.addEventListener(`keydown`, onEscPressModalHandler);
    });
  }

  const phoneInputElement = callbackModalElement.querySelector(`input[name="phone"]`);

  const sendRequestButton = document.querySelector(`.js--sendRequestButton`);

  sendRequestButton.addEventListener(`click`, () => {
    const nameInput = callbackModalElement.querySelector(`input[name="name"]`);
    const emailInput = callbackModalElement.querySelector(`input[name="email"]`);
    const cityInput = callbackModalElement.querySelector(`input[name="city"]`);
    const captureInputElement = callbackModalElement.querySelector(`input[name="capture"]`);

    const wrapButtonElement = callbackModalElement.querySelector(`.modal__buttonWrap`);
    const errorMessageElement = callbackModalElement.querySelector(`.modal__errorMessage`);

    wrapButtonElement.dataset.mode = `progress`;
    errorMessageElement.textContent = ``;

    getData({
      [`set_business_feedback`]: {
        [`name`]: nameInput.value,
        [`phone`]: phoneInputElement.value,
        [`email`]: emailInput.value,
        [`city`]: cityInput.value,
        [`capture`]: captureInputElement.value,
      }
    })
    .then((response) => {
      if (response.result === `OK`) {
        callbackModalElement.dataset.mode = `result`;

        // callbackModalElement.classList.remove(`modal--show`);
        // document.removeEventListener(`keydown`, onEscPressModalHandler);

        nameInput.value = ``;
        phoneInputElement.value = ``;
        emailInput.value = ``;
        cityInput.value = ``;
      }
      if (response.result === `ERROR`) {
        errorMessageElement.textContent = response.error;
      }
    })
    .finally(() => {
      wrapButtonElement.dataset.mode = `button`;
    });
  });

  const isOnlyOneShop = Object.keys(shopListData).length === 1;

  const toggleWrapElement = document.querySelector(`.franchise-page__toggleWrap`);
  toggleWrapElement.dataset.show = !isOnlyOneShop;
  const contactWrapElement = document.querySelector(`.franchise-page__contact`);
  contactWrapElement.dataset.show = !isOnlyOneShop;

  const contentElement = document.querySelector(`.franchise-page__content`);
  contentElement.dataset.mode = isOnlyOneShop ? `b2c` : `b2b`;

  const offerToggles = document.querySelectorAll(`.franchise-page__toggle input`);
  Array.from(offerToggles).forEach((input) => {
    input.addEventListener(`change`, () => {
      if (!input.checked) {
        return;
      }
      const mode = input.value;
      contentElement.dataset.mode = mode;
    });
  });

  const B2CElement = document.querySelector(`.franchise-page__B2CWrap`);
  B2CElement.dataset.mode = isOnlyOneShop ? `one` : `more`;


  const cityB2CElement = document.querySelector(`.franchise-page__B2CContent--left`);
  if (!isOnlyOneShop) {
    const cities = parseCitiesData(shopListData, shopInfoData);
    renderCities(cityB2CElement.querySelector(`.cities__list`), cities);
  }

  if (shopInfoData.shop_id) {
    getPage(shopInfoData.shop_id, (data) => {
      renderContactContent(contactB2CElement.querySelector(`div`), data);
    });
  }
};

