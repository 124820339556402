import AbstractComponent from "./abstract-component";


const createTemplate = ({town, town_r: townR, addr, worktime, phone_full: phoneFull, dir_phone: dirPhone}) => {
  return (
    `<div>
      <h2><b>Пункт выдачи ${town}</b></h2>
      <p>
        <b>Адрес</b>: 
        <span>${town}</span>, 
        <span>${addr}</span>
      </p>
      ${worktime
      ? `<p>
          <b>Время работы</b>: 
          <span>${worktime}</span>
        </p>`
      : ``}
      ${phoneFull
      ? `<p>
          <b>Телефон магазина</b>: 
          <span>${phoneFull}</span>
        </p>`
      : ``}
      
      ${dirPhone
      ? `<p>
          <b>Телефон директора</b>: ${dirPhone}
        </p>`
      : ``}
      <p>
        <a href="/response">Обратная связь</a>
      </p>

      <p>Местоположение пункта выдачи на карте ${townR}</p>
      <div id="YMapsID" style="height: 500px;"></div>
    </div>`
  );
};


export default class ContactComponent extends AbstractComponent {
  constructor({data = ``}) {
    super();

    this._data = data;
  }

  getTemplate() {
    return createTemplate(this._data);
  }
}
