import AbstractComponent from "./abstract-component";


const createTemplate = (city) => {
  return (
    `<button class="contact__city" type="button">${city}</button>`
  );
};

export default class CityComponent extends AbstractComponent {
  constructor({city = ``}) {
    super();

    this._city = city;
  }

  getTemplate() {
    return createTemplate(this._city);
  }

  setClickHanlder(handler) {
    this.getElement().addEventListener(`click`, handler);
  }
}
