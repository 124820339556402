import AbstractComponent from "./abstract-component";

const createTemplate = (login, sendPassText) => {
  return (
    `<div class="authorization__screen authorization__screen--loginPassword">
      <div class="modal__titleWrap">
        <button class="modal__backButton" type="button">
          <svg aria-hidden="true" width="24" height="24">
            <use class="" href="sienge/img/sprite.svg#arrow-left"></use>
          </svg>
          Назад
        </button>
        <h2 class="authorization__title modal__title">Вход по паролю</h2>
      </div>

      <div class="authorization__inputWrap">
        <p class="authorization__input input-placeholder">
          <input class="js--loginInput" type="text" placeholder=" " name="login" id="login" value="${login}">
          <label>Email или телефон</label>
          <button class="authorization__clearButton js--clearButton" type="button" title="Очистить" data-show="${!!login}" data-name="login">
            <svg aria-hidden="true" width="22" height="22">
              <use class="" href="sienge/img/sprite.svg#icon-clear"></use>
            </svg>
          </button>
        </p>

        <p class="authorization__input input-placeholder">
          <input class="js--passwordInput" type="password" placeholder=" " name="password" id="password">
          <label>Пароль</label>
          <button class="authorization__clearButton js--clearButton" type="button" title="Очистить" data-show="false" data-name="password">
            <svg aria-hidden="true" width="22" height="22">
              <use class="" href="sienge/img/sprite.svg#icon-clear"></use>
            </svg>
          </button>
        </p>
      </div>

      <p class="authorization__text js--sendPasswordText">${sendPassText}</p>
      
      <p class="authorization__errorMessage" data-show="false"></p>
      
      <div class="authorization__buttonWrap" data-mode="button">
        <button class="authorization__button button-primary js--loginButton" type="button">Войти</button>
        <div class="modal__progress">
          <img src="/sienge/img/load_bar.gif" alt="">
        </div>
      </div>

      <button class="button-tertiary js--recoveryPasswordButton" type="button">Забыли пароль?</button>
    </div>`
  );
};

export default class LoginPasswordScreenAuthModalComponent extends AbstractComponent {
  constructor({login = ``, sendPassText = ``}) {
    super();

    this._login = login;
    this._sendPassText = sendPassText;
  }

  getTemplate() {
    return createTemplate(this._login, this._sendPassText);
  }

  setBackButtonClickHandler(handler) {
    this.getElement().querySelector(`.modal__backButton`)
    .addEventListener(`click`, handler);
  }

  setLoginButtonClickHandler(handler) {
    this.getElement().querySelector(`.js--loginButton`)
    .addEventListener(`click`, handler);
  }

  setRecoveryPasswordButtonClickHandler(handler) {
    this.getElement().querySelector(`.js--recoveryPasswordButton`)
    .addEventListener(`click`, handler);
  }

  setClearButtonClickHandler(handler) {
    const clearButtons = this.getElement().querySelectorAll(`.js--clearButton`);

    Array.from(clearButtons).forEach((button) => {
      button.addEventListener(`click`, () => {
        const name = button.dataset.name;
        this.getElement().querySelector(`input[name="${name}"]`).value = ``;
        button.dataset.show = `false`;
        handler(name);
      });
    });
  }

  setInputFocus(name) {
    const input = this.getElement().querySelector(`input[name="${name}"]`);
    input.focus();
    if (input && input.selectionStart !== null) {
      input.selectionStart = input.value.length;
    }
  }

  setInputHandler(handler) {
    const inputs = this.getElement().querySelectorAll(`input`);

    Array.from(inputs).forEach((input) => {
      input.addEventListener(`input`, (evt) => {
        const inputElement = evt.target;

        const name = inputElement.name;
        const value = inputElement.value;
        this.getElement().querySelector(`.js--clearButton[data-name="${name}"]`).dataset.show = !!value;

        handler(inputElement.name, value);
      });
    });
  }

  setEnterKeydownInputHandler(handler) {
    const inputs = this.getElement().querySelectorAll(`input`);

    Array.from(inputs).forEach((input) => {
      input.addEventListener(`keydown`, (evt) => {
        const isEnterKeyDown = evt.key === `Enter`;

        if (!isEnterKeyDown) {
          return;
        }

        const inputElement = evt.target;

        if (!inputElement.value) {
          return;
        }

        handler(inputElement.name, inputElement.value);
      });
    });
  }
}
