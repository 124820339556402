export const getFormattedPhone = (phone) => {
  if (!phone) {
    return ``;
  }

  const mask = `* *** *** ****`;
  let text = ``;
  let i = 0;

  Array.from(mask).forEach((symbol) => {
    if (symbol === `*`) {
      text = `${text}${phone[i]}`;
      i++;
    }

    if (symbol === ` `) {
      text = `${text}&nbsp;`;
    }
  });

  return text;
};
