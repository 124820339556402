import AbstractComponent from "./abstract-component";

const createTemplate = (message = ``, isDelay = true) => {
  return (
    `<div class="authorization__screen authorization__screen--SMSCode">
      <div class="modal__titleWrap">
        <button class="modal__backButton" type="button">
          <svg aria-hidden="true" width="24" height="24">
            <use class="" href="sienge/img/sprite.svg#arrow-left"></use>
          </svg>
          Назад
        </button>
        <h2 class="authorization__title modal__title">Введите SMS-код</h2>
      </div>

      <p class="authorization__text">${message}</p>

      <p class="authorization__input">
        <input class="js--smsCodeInput" type="number" inputmode="numeric" placeholder=" " name="code" id="code" autocomplete="one-time-code">
      </p>

      <p class="authorization__errorMessage" data-show="false"></p>

      <div class="authorization__buttonWrap" data-mode="button" data-is-delay="${isDelay}">
        <button class="authorization__button authorization__button--repeat button-primary js--repeatCodeButton" data-disabled="false" type="button">Отправить код еще раз</button>
        <button class="authorization__button authorization__button--timer button-primary js--timerButton" data-disabled="true" type="button">Запросить код повторно<br>через <b><span></span></b></button>
        <div class="modal__progress">
          <img src="/sienge/img/load_bar.gif" alt="">
        </div>
      </div>

    </div>`
  );
};

// <p class="authorization__text js--timerGetCodeText" style="margin-bottom: 0;">Запросить новый код <br>вы сможете через 10 минут</p>
// {/* <button class="authorization__button button-primary js--setAuthSmsCodeButton" type="button">Ввести код</button> */}


export default class CodeScreenAuthModalComponent extends AbstractComponent {
  constructor({message = ``, isDelay = true}) {
    super();

    this._message = message;
    this._isDelay = isDelay;
  }

  getTemplate() {
    return createTemplate(this._message, this._isDelay);
  }

  setBackButtonClickHandler(handler) {
    this.getElement().querySelector(`.modal__backButton`)
    .addEventListener(`click`, handler);
  }

  setInProgress() {
    const wrapButtonElement = this.getElement().querySelector(`.authorization__buttonWrap`);
    const errorMessageElement = this.getElement().querySelector(`.authorization__errorMessage`);
    wrapButtonElement.dataset.mode = `progress`;
    errorMessageElement.dataset.show = `false`;
    errorMessageElement.textContent = ``;
  }

  showError(textError = ``) {
    const wrapButtonElement = this.getElement().querySelector(`.authorization__buttonWrap`);
    const errorMessageElement = this.getElement().querySelector(`.authorization__errorMessage`);
    errorMessageElement.dataset.show = `true`;
    errorMessageElement.textContent = textError;
    wrapButtonElement.dataset.mode = `button`;

    const wrap = this.getElement().querySelector(`.authorization__input`);
    wrap.classList.add(`shake`);

    this.setInputFocus();

    setTimeout(() => wrap.classList.remove(`shake`), 1000);
  }

  setIsDelay(data) {
    const wrapButtonElement = this.getElement().querySelector(`.authorization__buttonWrap`);
    wrapButtonElement.dataset.isDelay = data;
  }

  // setAuthCodeButtonClickHandler(handler) {
  //   this.getElement().querySelector(`.js--setAuthSmsCodeButton`)
  //   .addEventListener(`click`, handler);
  // }

  setRepeatCodeButtonClickHandler(handler) {
    this.getElement().querySelector(`.js--repeatCodeButton`)
    .addEventListener(`click`, handler);
  }

  setTimerCodeButtonClickHandler(handler) {
    this.getElement().querySelector(`.js--timerButton`)
    .addEventListener(`click`, handler);
  }

  setInputFocus() {
    this.getElement().querySelector(`.js--smsCodeInput`).focus();
  }

  clearInput() {
    this.getElement().querySelector(`.js--smsCodeInput`).value = ``;
  }

  setInputHandler(handler) {
    const errorMessageElement = this.getElement().querySelector(`.authorization__errorMessage`);

    this.getElement().querySelector(`.js--smsCodeInput`)
    .addEventListener(`input`, (evt) => {
      errorMessageElement.dataset.show = `false`;
      errorMessageElement.textContent = ``;

      const inputElement = evt.target;
      handler(inputElement.value);
    });
  }

  setEnterKeydownInputHandler(handler) {
    this.getElement().querySelector(`.js--smsCodeInput`)
    .addEventListener(`keydown`, (evt) => {
      const isEnterKeyDown = evt.key === `Enter`;

      if (!isEnterKeyDown) {
        return;
      }

      if (!evt.target.value) {
        return;
      }

      handler();
    });
  }
}
