export const MOBILE_MENU_ITEMS = [
  {
    id: `about`,
    title: `О компании`,
    link: `/about`
  },
  {
    id: `catalog`,
    title: `Каталог`,
    link: `/catalog`
  },
  {
    id: `delivery`,
    title: `Доставка`,
    link: `/delivery`
  },
  {
    id: `garant`,
    title: `Гарантия`,
    link: `/garant`
  },
  {
    id: `bonus`,
    title: `Бонусы`,
    link: `/oplata#bonus`
  },
  {
    id: `contact`,
    title: `Контакты`,
    link: `/contact`
  },
  {
    id: `franchise`,
    title: `Сотрудничество`,
    link: `/fr`
  },
  {
    id: `response`,
    title: `Обратная связь`,
    link: `/response`
  },
];

export const DESCTOP_MENU_ITEMS = [
  {
    id: `contact`,
    title: `Контакты`,
    link: `/contact`
  },
  {
    id: `bonus`,
    title: `Бонусы`,
    link: `/oplata#bonus`
  },
  {
    id: `garant`,
    title: `Гарантия`,
    link: `/garant`
  },
  {
    id: `delivery`,
    title: `Доставка`,
    link: `/delivery`
  },
  {
    id: `franchise`,
    title: `Сотрудничество`,
    link: `/fr`
  },
  {
    id: `response`,
    title: `Обратная связь`,
    link: `/response`
  },
];
