import AbstractComponent from "./abstract-component";

const createTemplate = () => {
  return (
    `<section class="modal authorization">
      <div class="modal__overlay"></div>
      <div class="modal__wrap authorization__modalWrap">
        <button class="modal__closeButton" type="button" title="Закрыть">
          <svg aria-hidden="true" width="16" height="16">
            <use class="" href="sienge/img/sprite.svg#close"></use>
          </svg>
        </button>
      </div>
    </section>`
  );
};

export default class AuthModalComponent extends AbstractComponent {
  constructor(className = ``) {
    super();

    this._className = className;
  }

  getTemplate() {
    return createTemplate(this._className);
  }

  setShowClass() {
    this.getElement().classList.add(`modal--show`);
  }

  setCloseButtonClickHandler(handler) {
    this.getElement().querySelector(`.modal__closeButton`)
    .addEventListener(`click`, handler);
  }

  setOverlayClickHandler(handler) {
    this.getElement().querySelector(`.modal__overlay`)
    .addEventListener(`click`, handler);
  }
}
