import {getData} from "../api/get-data";

export const getPage = (cityId, resolveHandler = () => {}, rejectHanlder = () => {}) => {
  getData({
    [`get_page`]: {
      [`page`]: `contact`,
      [`shop_id`]: cityId,
    }
  })
  .then((response) => {
    if (response.result) {
      resolveHandler(response.result);
    }

    rejectHanlder();
  })
  .catch(() => {
    rejectHanlder();
  });
};
