import {getData} from "../api/get-data";

export const setAuthCode = (phone, code, resolveHandler, rejectHanlder) => {
  getData({
    [`set_auth_code`]: {
      [`phone`]: phone,
      [`code`]: code,
    }
  })
  .then((response) => {
    if (response.result === `OK`) {
      resolveHandler();
    }
    if (response.result === `ERROR`) {
      rejectHanlder(response.error);
    }
  })
  .catch(() => {
    rejectHanlder();
  });
};
