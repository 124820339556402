import {getFormattedPrice} from "./utils/get-formatted-price";
import {getBonusNumeralEnding} from "./utils/get-numeral-ending";

export const updateBonuses = () => {
  if (!window.userSessionData) {
    return;
  }

  if (!cartData) {
    return;
  }

  if (!cartData.bonus_allow) {
    return;
  }

  const cartPageElement = document.querySelector(`.cart-page`);
  const bonusWrapElement = cartPageElement.querySelector(`.cart-page__bonusWrap`);
  const totalPriceElement = cartPageElement.querySelector(`.cart-page__totalPrice`);

  const bonusCountElement = bonusWrapElement.querySelector(`.cart-page__bonusCount`);
  const bonusInputWrapElement = bonusWrapElement.querySelector(`.cart-page__bonusInput`);
  const bonusInputElement = bonusInputWrapElement.querySelector(`input`);

  if (+cartData.bonus_used > 0) {
    bonusWrapElement.dataset.mode = `input`;
    bonusInputWrapElement.dataset.mode = `apply`;
    bonusInputElement.value = cartData.bonus_used;
    bonusCountElement.innerHTML = `Осталось ${getBonusNumeralEnding(+window.userSessionData.bonus_sum - +cartData.bonus_used)}`;
  } else {
    bonusWrapElement.dataset.mode = `button`;
    bonusInputWrapElement.dataset.mode = `empty`;
    bonusInputElement.value = ``;
    bonusCountElement.innerHTML = `У вас есть ${getBonusNumeralEnding(+window.userSessionData.bonus_sum)}`;
  }

  totalPriceElement.innerHTML = getFormattedPrice(cartData.fullprice);
};
