export const getAlphabetCities = (cities) => {
  const results = [];

  cities.forEach((city) => {
    const firstLetter = city.title[0];
    const index = results.findIndex((result) => result.firstLetter === firstLetter);

    if (index === -1) {
      results.push({
        firstLetter,
        cities: [city],
      });
    } else {
      results[index].cities.push(city);
    }
  });

  return results.slice().sort((a, b) => {
    if (a.firstLetter > b.firstLetter) {
      return 1;
    }
    if (a.firstLetter < b.firstLetter) {
      return -1;
    }

    return 0;
  });
};
