import AbstractComponent from "./abstract-component";

const createTemplate = (className, title, link, svg) => {
  return (
    `<li class="${className}">
      <a href="${link}">${title}${svg}</a>
    </li>`
  );
};

export default class NavItemComponent extends AbstractComponent {
  constructor({className = ``, title = ``, link = ``, svg = ``}) {
    super();

    this._className = className;
    this._title = title;
    this._link = link;
    this._svg = svg;
  }

  getTemplate() {
    return createTemplate(this._className, this._title, this._link, this._svg);
  }
}
