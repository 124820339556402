const getNumeralEnding = (numeralEnding, count) => {
  if (!count && count !== 0) {
    return ``;
  }

  const string = count.toString();
  const lastChar = string.slice(-1);
  const twoLastChar = string.slice(-2);

  if (isNaN(count)) {
    return ``;
  }

  if (lastChar === `1` && twoLastChar !== `11`) {
    return `${count} <span>${numeralEnding.ONE}</span>`;
  }

  if (lastChar === `2` && twoLastChar !== `12`
    || lastChar === `3` && twoLastChar !== `13`
    || lastChar === `4` && twoLastChar !== `14`) {
    return `${count} <span>${numeralEnding.SEVERAL}</span>`;
  }

  return `${count} <span>${numeralEnding.MANY}</span>`;
};

export const getProductNumeralEnding = (count) => {
  const numeralEnding = {
    ONE: `товар`,
    SEVERAL: `товара`,
    MANY: `товаров`
  };
  return getNumeralEnding(numeralEnding, count);
};

export const getReviewNumeralEnding = (count) => {
  const numeralEnding = {
    ONE: `отзыв`,
    SEVERAL: `отзыва`,
    MANY: `отзывов`
  };
  return getNumeralEnding(numeralEnding, count);
};

export const getBonusNumeralEnding = (count) => {
  const numeralEnding = {
    ONE: `бонус`,
    SEVERAL: `бонуса`,
    MANY: `бонусов`
  };
  return getNumeralEnding(numeralEnding, count);
};

export const getItemNumeralEnding = (count) => {
  const numeralEnding = {
    ONE: `позиция`,
    SEVERAL: `позиции`,
    MANY: `позиций`
  };
  return getNumeralEnding(numeralEnding, count);
};
