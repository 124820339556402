export const getPhoneNumber = (phone) => {
  if (!phone) {
    return ``;
  }

  if (phone[0] === `8`) {
    return `tel:+7${phone.slice(1)}`;
  }

  return `tel:${phone}`;
};
