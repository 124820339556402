import {getData} from "./api/get-data";
import {createAnchor} from "./create/create-ahchor";
import {createDiv} from "./create/create-div";
import {createImage} from "./create/create-image";
import {createList} from "./create/create-list";
import {createListItem} from "./create/create-list-item";
import {createParagraph} from "./create/create-paragraph";
import {createPriceElement} from "./create/create-price";
import {debounce} from "./utils/debounce";
import {isEmptyObject} from "./utils/is-empty-object";

export const initSearch = () => {
  const searchElement = document.querySelector(`.search`);
  if (!searchElement) {
    return;
  }
  // переключение на режим поиска на мобилке
  const headerElement = document.querySelector(`.main-header`);
  const onSearchModeButton = headerElement.querySelector(`.main-header__searchButton`);
  if (onSearchModeButton) {
    onSearchModeButton.addEventListener(`click`, () => {
      headerElement.dataset.isSearch = true;
      searchElement.dataset.showResult = true;
      document.querySelector(`body`).classList.add(`overflowHidden`);
    });
  }

  const offSearchModeButton = headerElement.querySelector(`.main-header__cancelSearchButton`);
  if (offSearchModeButton) {
    offSearchModeButton.addEventListener(`click`, () => {
      headerElement.dataset.isSearch = false;
      searchElement.dataset.showResult = false;
      document.querySelector(`body`).classList.remove(`overflowHidden`);
    });
  }

  const searchInputElement = searchElement.querySelector(`.search__input`);
  const searchResultElement = searchElement.querySelector(`.search__result`);
  const clearButton = searchElement.querySelector(`.search__clearButton`);

  const renderSearchResult = (searchData) => {
    searchElement.dataset.showResult = true;
    document.querySelector(`body`).classList.add(`overflowHidden`);
    searchResultElement.innerHTML = ``;

    if ((!searchData.groups || isEmptyObject(searchData.groups)) && (searchData.items || isEmptyObject(searchData.items))) {
      const resultNotFoundElement = createParagraph(`search__resultNotFound`, `Не найдено`);
      searchResultElement.append(resultNotFoundElement);
      return;
    }

    if (!isEmptyObject(searchData.groups)) {
      const groupsResultWrapElement = createDiv(`search__groupsResultWrap`);
      searchResultElement.append(groupsResultWrapElement);

      const groupsListElement = createList(`search__groupsResultList`);
      groupsResultWrapElement.append(groupsListElement);

      Object.keys(searchData.groups).forEach((itemId) => {
        const group = searchData.groups[itemId];

        const liItemElement = createListItem(`search__groupsResultItem`);
        groupsListElement.append(liItemElement);

        const anchorElement = createAnchor(`search__groupsResultItemLink`, group.name, group.link);
        liItemElement.append(anchorElement);
      });
    }

    if (!isEmptyObject(searchData.items)) {
      const itemsResultWrapElement = createDiv(`search__itemsResultWrap`);
      searchResultElement.append(itemsResultWrapElement);

      const itemsListElement = createList(`search__itemsResultList`);
      itemsResultWrapElement.append(itemsListElement);

      Object.keys(searchData.items).forEach((itemId) => {
        const item = searchData.items[itemId];

        const liItemElement = createListItem(`search__itemsResultItem`);
        itemsListElement.append(liItemElement);

        const anchorElement = createAnchor(`search__itemsResultItemLink`, ``, item.item_link);
        liItemElement.append(anchorElement);

        const imgElement = createImage(`search__itemsResultItemImage`, item.item_image, item.item_name);
        anchorElement.append(imgElement);

        const titleParagraphElement = createParagraph(`search__itemsResultItemTitle`, item.item_name);
        anchorElement.append(titleParagraphElement);

        if (item.item_act) {
          const priceParagraphElement = createPriceElement(`search__itemsResultItemPrice`, item.item_price);
          anchorElement.append(priceParagraphElement);
        } else {
          const noAvailableParagraphElement = createParagraph(`search__itemsResultItemNotAvailable`, `Нет в наличии`);
          anchorElement.append(noAvailableParagraphElement);
        }

      });

      const showAllLinkElement = createAnchor(`search__showAllLink`, `Посмотреть все результаты`, `/search?s=${searchData.filter.name}`);
      itemsResultWrapElement.append(showAllLinkElement);
    }
  };

  const onSearchInputHandler = () => {
    const value = searchInputElement.value;

    if (!value) {
      searchResultElement.dataset.mode = `hide`;
    } else {
      getData({
        [`get_search`]: value,
      })
      .then((response) => {
        if (response.result === `OK`) {
          renderSearchResult(response);
        }
      });
    }
  };

  searchInputElement.addEventListener(`input`, debounce(onSearchInputHandler));
  searchInputElement.addEventListener(`input`, () => {
    clearButton.dataset.mode = searchInputElement.value ? `show` : `hide`;
  });

  clearButton.addEventListener(`click`, () => {
    searchInputElement.value = ``;
    clearButton.dataset.mode = `hide`;
    searchElement.dataset.showResult = false;
    document.querySelector(`body`).classList.remove(`overflowHidden`);
  });
};
