import AbstractComponent from "./abstract-component";

const createTemplate = (logo, isShowMenu) => {
  return (
    `<div class="main-header__wrap container">
      <button class="main-header__burger burger" data-show-menu="${isShowMenu}" type="button" title="Открыть меню"></button>

      <div class="main-header__left">
        <a class="main-header__logo logo" href="/">
          <img class="logo__img" src="${logo.src}" alt="${logo.title}" width="120" height="30">
        </a>

        <a class="main-header__catalogLink" href="/catalog">
          
          <button class="main-header__catalogButton button-secondary js--catalogButton" type="button">
          Каталог товаров
          </button>
        </a>
      </div>

      <div class="main-header__center">
        <div class="main-header__wrapCenter">
          <div class="main-header__contact contact contact--header"></div>
          <nav class="main-header__nav nav"></nav>
        </div>
      </div>
      
      <div class="main-header__right">
        <div class="main-header__search search" data-show-result="false">
          <form class="search__form" action="/search" id="search" method="get">
            <input class="search__input" type="search" name="s" placeholder="Поиск по товарам" autocomplete="off">
            <button class="search__clearButton" type="button" data-mode="hide">Очистить
              <svg aria-hidden="true" width="14" height="14">
                <use class="" href="sienge/img/sprite.svg#icon-close"></use>
              </svg>
            </button>
            <button class="search__button" type="submit">Найти
              <svg aria-hidden="true" width="18" height="18">
                <use class="" href="sienge/img/sprite.svg#icon-search"></use>
              </svg>
            </button>
          </form>
          <div class="search__result"></div>
        </div>

        <button class="main-header__searchButton" type="button">Поиск
          <svg aria-hidden="true" width="22" height="22">
            <use class="" href="sienge/img/sprite.svg#icon-search"></use>
          </svg>
        </button>
        <button class="main-header__cancelSearchButton" type="button">
          Отменить
        </button>

        <div class="main-header__userBlock userBlock">
          <ul class="userBlock__list">
            <li class="userBlock__item userBlock__item--main">
              <a href="/">
                <svg aria-hidden="true" width="18" height="18">
                  <use class="" href="sienge/img/sprite.svg#icon-home"></use>
                </svg>
                Главная
              </a>
            </li>
            <li class="userBlock__item userBlock__item--catalog">
              <button class="js--catalogButton" type="button">
                <svg aria-hidden="true" width="18" height="18">
                  <use class="" href="sienge/img/sprite.svg#icon-list-bulleted"></use>
                </svg>
                Каталог
              </button>
            </li>
            <li class="userBlock__item userBlock__item--contacts">
              <a href="/contact">
                <svg aria-hidden="true" width="18" height="18">
                  <use class="" href="sienge/img/sprite.svg#icon-phone"></use>
                </svg>
                Контакты
              </a>
            </li>
            <li class="userBlock__item userBlock__item--user">
              <button class="js--authorizationButton" type="button">
                <svg aria-hidden="true" width="18" height="18">
                  <use class="" href="sienge/img/sprite.svg#icon-user"></use>
                </svg>
                <span>...</span>
              </button>
            </li>
            <li class="userBlock__item userBlock__item--favorites">
              <a class="js--favoritesHeaderLink" href="/favorites" data-count="">
                <svg aria-hidden="true" width="18" height="18">
                  <use class="" href="sienge/img/sprite.svg#icon-favorite-stroke"></use>
                </svg>
                Избранное
              </a>
            </li>
            <li class="userBlock__item userBlock__item--basket">
              <a class="js--cartHeaderLink" href="/cart" data-count="">
                <svg aria-hidden="true" width="18" height="18">
                  <use class="" href="sienge/img/sprite.svg#icon-basket"></use>
                </svg>
                Корзина
              </a>
            </li>
          </ul>
        </div>
        
      </div>
    </div>`
  );
};

export default class HeaderComponent extends AbstractComponent {
  constructor({logo = ``}) {
    super();

    this._logo = logo;
    this._isShowMenu = false;

    this.onHideMenuHandler = this.onHideMenuHandler.bind(this);
  }

  getTemplate() {
    return createTemplate(this._logo, this._isShowMenu);
  }

  onHideMenuHandler() {
    this._isShowMenu = false;
    this.getElement().querySelector(`.main-header__burger`).dataset.showMenu = this._isShowMenu;
  }

  setBurgerButtonClickHanlder(handler) {
    this.getElement().querySelector(`.main-header__burger`)
    .addEventListener(`click`, (evt) => {
      const button = evt.target;
      this._isShowMenu = !this._isShowMenu;
      button.dataset.showMenu = this._isShowMenu;

      handler(this._isShowMenu);
    });
  }

  setCatalogButtonClickHandler(handler) {
    const buttons = this.getElement().querySelectorAll(`.js--catalogButton`);
    Array.from(buttons).forEach((button) => {
      button.addEventListener(`click`, (evt) => {
        evt.preventDefault();
        handler();
      });
    });
  }
}
