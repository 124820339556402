import CatalogComponent from "../components/catalog-component";
import CatalogModalComponent from "../components/catalog-modal-component";
import {RenderPosition, remove, render} from "../utils/render";


export default class CatalogController {
  constructor({logoData, catalogList}) {
    this._logoData = logoData;
    this._catalogList = catalogList;
    this._authComponent = null;

    this._catalogItemElements = [];

    this._onEscPressModalHandler = this._onEscPressModalHandler.bind(this);
  }

  show() {
    document.querySelector(`body`).classList.add(`overflowHidden`);
    document.addEventListener(`keydown`, this._onEscPressModalHandler);

    this._authComponent = new CatalogModalComponent({
      logoData: this._logoData,
    });
    render(document.querySelector(`body`), this._authComponent.getElement(), RenderPosition.BEFOREEND);

    this._renderCatalog(this._authComponent.getElement().querySelector(`.catalog__list`));

    this._authComponent.setCloseButtonClickHandler(() => this._remove());
    setTimeout(() => this._authComponent.setShowClass(), 100);
  }

  _renderCatalog(container) {
    const catalogList = this._catalogList;
    Object.keys(catalogList)
    .filter((key) => key !== `current`)
    .forEach((itemId) => {
      const component = new CatalogComponent({
        catalogItem: catalogList[itemId]
      });

      component.setMouseOverHandler(() => {
        this._inactivateCatalogItems();
        component.activate();
      });
      render(container, component.getElement(), RenderPosition.BEFOREEND);
    });

    this._catalogItemElements = container.querySelectorAll(`.catalog__item`);
  }

  _inactivateCatalogItems() {
    Array.from(this._catalogItemElements).forEach((elem) => {
      elem.classList.remove(`catalog__item--active`);
    });
  }

  _remove() {
    this._authComponent.getElement().classList.remove(`popup--show`);
    document.querySelector(`body`).classList.remove(`overflowHidden`);
    document.removeEventListener(`keydown`, this._onEscPressModalHandler);
    setTimeout(() => remove(this._authComponent), 1000);
  }

  _onEscPressModalHandler(evt) {
    if (evt.key === `Escape`) {
      this._remove();
    }
  }
}
