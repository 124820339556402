import DivComponent from "../components/div";
import AuthModalComponent from "../components/auth-modal-component";
import CodeScreenAuthModalComponent from "../components/code-screen-auth-modal-component";
import LoginPasswordScreenAuthModalComponent from "../components/login-password-screen-auth-modal-component";
import LoginScreenAuthModalComponent from "../components/login-screen-auth-modal-component";
import RecoveryPasswordScreenAuthModalComponent from "../components/recovery-password-screen-auth-modal-component";
import {getAuthCode} from "../require/get-auth-code";
import {getAuthPass} from "../require/get-auth-pass";
import {setAuthCode} from "../require/set-auth-code";
import {setAuthPass} from "../require/set-auth-pass";
import {isCorrectPhone} from "../utils/is-correct-phone";
import {RenderPosition, remove, render, replace} from "../utils/render";
import IMask from "imask";

const DELAY = 5 * 60;

const getNow = () => new Date().getTime();

const getAuthPhone = () => {
  if (!window.localStorage.getItem(`authPhone`)) {
    return {};
  }
  let phoneData = JSON.parse(window.localStorage.getItem(`authPhone`));

  const now = getNow();

  Object.keys(phoneData).forEach((phone) => {
    if (phoneData[phone] < now) {
      delete phoneData[phone];
    }
  });

  window.localStorage.setItem(`authPhone`, JSON.stringify(phoneData));

  return phoneData;
};


export default class AuthController {
  constructor() {
    this._data = {
      phone: ``,
      login: ``,
      password: ``,
    };
    this._authPhone = getAuthPhone();
    this._sendPassText = ``;
    this._authComponent = null;

    this._onEscPressModalHandler = this._onEscPressModalHandler.bind(this);

    this._activeScreen = `login`;
  }

  show() {
    document.querySelector(`body`).classList.add(`overflowHidden`);
    document.addEventListener(`keydown`, this._onEscPressModalHandler);

    this._authComponent = new AuthModalComponent();
    render(document.querySelector(`body`), this._authComponent.getElement(), RenderPosition.BEFOREEND);

    this._authComponent.setCloseButtonClickHandler(() => this._remove());
    this._authComponent.setOverlayClickHandler(() => this._remove());

    const modalWrapElement = this._authComponent.getElement().querySelector(`.modal__wrap`);
    this._renderScreen(modalWrapElement);

    this._authComponent.setShowClass();

    this._focusInput();
  }

  _focusInput() {
    const firstInput = this._authComponent.getElement().querySelector(`input`);
    if (firstInput) {
      firstInput.focus();
    }
  }

  _renderScreen(container) {
    const oldComponent = this._wrapComponent;
    this._wrapComponent = new DivComponent(`authorization__wrap`);

    if (oldComponent) {
      replace(this._wrapComponent, oldComponent);
    } else {
      render(container, this._wrapComponent.getElement(), RenderPosition.BEFOREEND);
    }

    switch (this._activeScreen) {
      case `login`:
        this._renderLoginScreen(this._wrapComponent.getElement());
        break;
      case `SMSCode`:
        this._renderCodeScreen(this._wrapComponent.getElement());
        break;
      case `loginPassword`:
        this._renderLoginPasswordScreen(this._wrapComponent.getElement());
        break;
      case `recoveryPassword`:
        this._renderRecoveryPasswordScreen(this._wrapComponent.getElement());
        break;
    }
  }

  _getDeadline(phone) {
    const deadline = this._authPhone[phone];
    if (!deadline) {
      return 0;
    }

    return deadline > getNow() ? deadline : 0;
  }

  _renderLoginScreen(container) {
    const isDisabledAuthButton = () => !isCorrectPhone(this._data.phone);
    const currentDeadline = this._getDeadline(this._data.phone);

    const component = new LoginScreenAuthModalComponent({
      phone: this._data.phone,
      isDelay: currentDeadline > getNow()
    });
    const authButton = component.getElement().querySelector(`.js--getAuthCodeButton`);
    authButton.dataset.disabled = isDisabledAuthButton();
    render(container, component.getElement(), RenderPosition.BEFOREEND);

    const checkLoginSreen = () => {
      component.setInProgress();

      if (!isCorrectPhone(this._data.phone)) {
        component.showError(`Введите номер телефона полностью`);
        return;
      }

      this._getAuthCode(component);
    };

    const phoneInput = component.getElement().querySelector(`.js--phoneInput`);
    const phoneMask = IMask(phoneInput, {
      mask: `+{7} (000) 000-00-00`
    });

    component.setInputFocus();
    component.setPasswordButtonClickHandler(() => this._switchMode(`loginPassword`));
    component.setGetAuthCodeButtonClickHandler(() => checkLoginSreen());
    component.setSetAuthCodeButtonClickHandler(() => this._switchMode(`SMSCode`));

    component.setClearButtonClickHandler(() => {
      phoneMask.value = ``;
      this._data[`phone`] = ``;
      if (authButton) {
        authButton.dataset.disabled = isDisabledAuthButton();
      }
      component.setInputFocus();
    });

    component.setInputHandler((value) => {
      this._data[`phone`] = value;

      const isDelay = !!this._getDeadline(this._data.phone);
      component.setIsDelay(isDelay);
      authButton.dataset.disabled = isDisabledAuthButton();
    });

    component.setEnterKeydownInputHandler(() => {
      checkLoginSreen();
    });
  }

  _renderCodeScreen(container) {
    let code = ``;
    const currentDeadline = this._getDeadline(this._data.phone);

    const delay = Math.ceil((currentDeadline - getNow()) / 1000);

    const component = new CodeScreenAuthModalComponent({
      message: `Мы отправили код подтверждения на&nbsp;номер&nbsp;<b>${this._data.phone}</b>`,
      isDelay: currentDeadline > getNow(),
    });
    const timerElement = component.getElement().querySelector(`.js--timerButton span`);

    if (delay > 0) {
      this._runTimer(timerElement, delay, () => {
        component.setIsDelay(false);
      });
    }

    render(container, component.getElement(), RenderPosition.BEFOREEND);

    const checkCodeScreen = () => {
      component.setInProgress();

      const resolveHandler = () => {
        window.location.reload();
      };

      const rejectHanlder = (textError = ``) => {
        code = ``;
        component.clearInput();
        component.showError(textError);
      };

      // setTimeout(() => rejectHanlder(`ошибка`), 2000);

      setAuthCode(this._data.phone, code, resolveHandler, rejectHanlder);
    };

    component.setInputFocus();
    component.setBackButtonClickHandler(() => this._switchMode(`login`));
    component.setRepeatCodeButtonClickHandler(() => {
      component.setInProgress();
      this._getAuthCode(component);
    });
    component.setTimerCodeButtonClickHandler(() => {
      component.setInputFocus();
    });
    component.setInputHandler((value) => {
      code = value;

      if (code.length === 4) {
        checkCodeScreen();
      }
    });
  }

  _getAuthCode(component) {
    const resolveHandler = () => {
      const deadline = (getNow()) + (DELAY * 1000);

      this._authPhone[this._data.phone] = deadline;
      window.localStorage.setItem(`authPhone`, JSON.stringify(this._authPhone));

      component.setIsDelay(true);

      this._switchMode(`SMSCode`);
    };

    const rejectHanlder = (textError = ``) => {
      component.showError(textError);
    };

    // setTimeout(() => resolveHandler(), 2000);

    getAuthCode(this._data.phone, resolveHandler, rejectHanlder);
  }

  _parseTime(time) {
    const minutes = parseInt(time / 60, 10);
    const seconds = parseInt(time % 60, 10);

    const data = {
      [`[mm]`]: minutes < 10 ? `0${minutes}` : String(minutes),
      [`[ss]`]: seconds < 10 ? `0${seconds}` : String(seconds),
    };

    return `${data[`[mm]`]}:${data[`[ss]`]}`;
  }

  _runTimer(timerElement, delay, timerEndedHandler) {
    let timerId = null;

    const countdownTimer = () => {
      delay -= 1;
      if (delay <= 0) {
        clearInterval(timerId);
        timerEndedHandler();
      }
      timerElement.innerHTML = this._parseTime(delay);
    };

    countdownTimer();

    timerId = setInterval(countdownTimer, 1000);
  }

  _renderLoginPasswordScreen(container) {
    const isDisabledLoginButton = () => !this._data.login || !this._data.password;

    const component = new LoginPasswordScreenAuthModalComponent({login: this._data.login, sendPassText: this._sendPassText});
    const wrapButtonElement = component.getElement().querySelector(`.authorization__buttonWrap`);
    const errorMessageElement = component.getElement().querySelector(`.authorization__errorMessage`);
    const loginButton = component.getElement().querySelector(`.js--loginButton`);
    loginButton.dataset.disabled = isDisabledLoginButton();
    render(container, component.getElement(), RenderPosition.BEFOREEND);

    const checkLoginPasswordScreen = () => {
      wrapButtonElement.dataset.mode = `progress`;
      errorMessageElement.textContent = ``;

      if (!this._data.login) {
        errorMessageElement.dataset.show = `true`;
        errorMessageElement.textContent = `Введите email или телефон`;
        component.setInputFocus(`login`);

        wrapButtonElement.dataset.mode = `button`;

        return;
      }

      if (!this._data.password) {
        errorMessageElement.dataset.show = `true`;
        errorMessageElement.textContent = `Введите пароль`;
        component.setInputFocus(`password`);

        wrapButtonElement.dataset.mode = `button`;

        return;
      }

      const resolveHandler = () => {
        window.location.reload();
      };

      const rejectHanlder = (textError = ``) => {
        errorMessageElement.dataset.show = `true`;
        errorMessageElement.textContent = textError;
        wrapButtonElement.dataset.mode = `button`;
      };

      setAuthPass(this._data.login, this._data.password, resolveHandler, rejectHanlder);
    };


    if (!this._data.login) {
      component.setInputFocus(`login`);
    } else if (!this._data.password) {
      component.setInputFocus(`password`);
    }

    component.setBackButtonClickHandler(() => this._switchMode(`login`));
    component.setLoginButtonClickHandler(() => checkLoginPasswordScreen());
    component.setRecoveryPasswordButtonClickHandler(() => this._switchMode(`recoveryPassword`));
    component.setClearButtonClickHandler((name) => {
      errorMessageElement.dataset.show = `false`;
      errorMessageElement.textContent = ``;
      this._data[name] = ``;

      loginButton.dataset.disabled = isDisabledLoginButton();

      component.setInputFocus(name);
    });

    component.setInputHandler((name, value) => {
      errorMessageElement.dataset.show = `false`;
      errorMessageElement.textContent = ``;
      this._data[name] = value;

      loginButton.dataset.disabled = isDisabledLoginButton();
    });

    component.setEnterKeydownInputHandler((name) => {
      if (name === `login`) {
        if (!this._data.password) {
          component.setInputFocus(`password`);
          return;
        }

        checkLoginPasswordScreen();
      }

      if (name === `password`) {
        if (!this._data.login) {
          component.setInputFocus(`login`);
          return;
        }

        checkLoginPasswordScreen();
      }
    });
  }

  _renderRecoveryPasswordScreen(container) {
    const isDisabledRecoveryPasswordButton = () => !this._data.login;

    const component = new RecoveryPasswordScreenAuthModalComponent({login: this._data.login});
    const wrapButtonElement = component.getElement().querySelector(`.authorization__buttonWrap`);
    const errorMessageElement = component.getElement().querySelector(`.authorization__errorMessage`);
    const recoveryButton = component.getElement().querySelector(`.js--getPasswordButton`);
    recoveryButton.dataset.disabled = isDisabledRecoveryPasswordButton();
    render(container, component.getElement(), RenderPosition.BEFOREEND);

    const checkRecoveryPasswordScreen = () => {
      wrapButtonElement.dataset.mode = `progress`;
      errorMessageElement.textContent = ``;

      if (!this._data.login) {
        errorMessageElement.dataset.show = `true`;
        errorMessageElement.textContent = `Введите email или телефон`;
        component.setInputFocus();

        wrapButtonElement.dataset.mode = `button`;

        return;
      }

      const resolveHandler = (response) => {
        this._sendPassText = this._getSendPasswordText(response);
        this._switchMode(`loginPassword`);
      };

      const rejectHanlder = (textError = ``) => {
        errorMessageElement.dataset.show = `true`;
        errorMessageElement.textContent = textError;
        wrapButtonElement.dataset.mode = `button`;
      };

      getAuthPass(this._data.login, resolveHandler, rejectHanlder);
    };


    component.setInputFocus();
    component.setBackButtonClickHandler(() => this._switchMode(`loginPassword`));
    component.setPasswordButtonClickHandler(() => checkRecoveryPasswordScreen());
    component.setClearButtonClickHandler(() => {
      errorMessageElement.dataset.show = `false`;
      errorMessageElement.textContent = ``;
      this._data[`login`] = ``;

      recoveryButton.dataset.disabled = isDisabledRecoveryPasswordButton();

      component.setInputFocus();
    });

    component.setInputHandler((value) => {
      errorMessageElement.dataset.show = `false`;
      errorMessageElement.textContent = ``;
      this._data[`login`] = value;

      recoveryButton.dataset.disabled = isDisabledRecoveryPasswordButton();
    });
    component.setEnterKeydownInputHandler(() => checkRecoveryPasswordScreen());
  }

  _switchMode(mode) {
    this._activeScreen = mode;
    this._renderScreen();
  }

  _getSendPasswordText(response) {
    switch (response.login) {
      case `email`:
        return `Пароль отправлен на e-mail ${response.email}`;
      case `phone`:
        return `Пароль отправлен на телефон ${response.phone}`;
      default:
        return ``;
    }
  }

  _remove() {
    this._authComponent.getElement().classList.add(`modal--hide`);
    document.querySelector(`body`).classList.remove(`overflowHidden`);
    document.removeEventListener(`keydown`, this._onEscPressModalHandler);
    setTimeout(() => remove(this._authComponent), 1000);
  }

  _onEscPressModalHandler(evt) {
    if (evt.key === `Escape`) {
      this._remove();
    }
  }


  // _setAuthPnoneCode() {
  //   const wrapButtonElement = this._codeScreenAuthElement.querySelector(`.modal__buttonWrap`);
  //   const errorMessageElement = this._codeScreenAuthElement.querySelector(`.modal__errorMessage`);

  //   wrapButtonElement.dataset.mode = `progress`;
  //   errorMessageElement.textContent = ``;

  //   getData({
  //     [`set_auth_code`]: {
  //       [`phone`]: this._phoneAuth,
  //       [`code`]: this._phoneCodeInput.value,
  //     }
  //   })
  //   .then((response) => {
  //     if (response.result === `OK`) {
  //       window.location.reload();
  //     }
  //     if (response.result === `ERROR`) {
  //       errorMessageElement.textContent = response.error;
  //       wrapButtonElement.dataset.mode = `button`;
  //     }
  //   })
  //   .catch(() => {
  //     wrapButtonElement.dataset.mode = `button`;
  //   });
  // }

}


// const authorizationModalElement = document.querySelector(`.js--authorizationModal`);
// if (authorizationModalElement) {
//   authorizationModalElement.classList.add(`modal--show`);
//   document.addEventListener(`keydown`, onEscPressModalHandler);
//   authorizationModalElement.querySelector(`.js--phoneInput`).focus();
// }
