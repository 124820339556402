import {getData} from "./api/get-data";
import {setFavoritesHeader} from "./utils/set-favorites-header";

export const initFavorites = () => {
  const favoritesHeaderLink = document.querySelector(`.js--favoritesHeaderLink`);
  if (!favoritesHeaderLink) {
    return;
  }

  getData({
    [`get_header_favorites`]: ``,
  })
  .then((response) => {
    if (response.result === `OK`) {
      setFavoritesHeader(response.favorites_count);
    }

    if (response.result === `ERROR`) {
      favoritesHeaderLink.dataset.count = ``;
    }
  });
};
