export const getFormattedPrice = (price) => {
  let result = ``;

  Array.from(String(price)).reverse().forEach((symbol, i) => {
    const orderSymbol = i + 1;
    result = symbol + result;

    if (orderSymbol % 3 === 0) {
      result = ` ` + result;
    }
  });

  return result;
};
