import MenuPopupComponent from "../components/menu-popup-component";
import {MOBILE_MENU_ITEMS} from "../const/menu-items";
import {renderContact} from "../render-contact";
import {renderNav} from "../render-nav";
import {showCatalogPopup} from "../show-catalog-popup";
import {RenderPosition, remove, render} from "../utils/render";


export default class MenuController {
  constructor({shopInfoData, logoData, catalogList, onHideModalHanlder}) {
    this._shopInfoData = shopInfoData;
    this._logoData = logoData;
    this._catalogList = catalogList;
    this._onHideModalHanlder = onHideModalHanlder;
    this._authComponent = null;

    this._catalogItemElements = [];

    this._onEscPressModalHandler = this._onEscPressModalHandler.bind(this);
  }

  show() {
    document.querySelector(`body`).classList.add(`overflowHidden`);
    document.addEventListener(`keydown`, this._onEscPressModalHandler);

    this._authComponent = new MenuPopupComponent({
      logoData: this._logoData,
    });
    render(document.querySelector(`body`), this._authComponent.getElement(), RenderPosition.BEFOREEND);

    this._renderContact(this._authComponent.getElement().querySelector(`.contact`));
    this._renderNavList(this._authComponent.getElement().querySelector(`.nav`));

    // this._authComponent.setCloseButtonClickHandler(() => this._remove());
    this._authComponent.setOverlayClickHandler(() => this._remove());
    this._authComponent.setCatalogButtonClickHandler(() => {
      showCatalogPopup(this._logoData, this._catalogList);
    });
    setTimeout(() => this._authComponent.setShowClass(), 100);
  }

  hide() {
    this._remove();
  }

  _renderContact(container) {
    renderContact(container, this._shopInfoData, this._logoData);
  }

  _renderNavList(container) {
    const listClassName = `nav__list`;
    const itemClassName = `nav__item`;

    const navItems = MOBILE_MENU_ITEMS
      .filter((item) => !(item.id === `franchise` && !this._shopInfoData.show_fr)
      && !(item.id === `bonus` && !this._shopInfoData.show_bonus));

    if (this._shopInfoData.real_link) {
      navItems.unshift({
        id: `show_link`,
        title: ``,
        link: this._shopInfoData.real_link,
        svg: `<svg width="18" height="18" viewBox="0 0 24 24" role="presentation"><path d="M12.654 8.764a.858.858 0 01-1.213-1.213l1.214-1.214a3.717 3.717 0 015.257 0 3.714 3.714 0 01.001 5.258l-1.214 1.214-.804.804a3.72 3.72 0 01-5.263.005.858.858 0 011.214-1.214c.781.782 2.05.78 2.836-.005l.804-.803 1.214-1.214a1.998 1.998 0 00-.001-2.831 2 2 0 00-2.83 0l-1.215 1.213zm-.808 6.472a.858.858 0 011.213 1.213l-1.214 1.214a3.717 3.717 0 01-5.257 0 3.714 3.714 0 01-.001-5.258l1.214-1.214.804-.804a3.72 3.72 0 015.263-.005.858.858 0 01-1.214 1.214 2.005 2.005 0 00-2.836.005l-.804.803L7.8 13.618a1.998 1.998 0 00.001 2.831 2 2 0 002.83 0l1.215-1.213z" fill="currentColor"></path></svg>`,
      });
    }

    renderNav(container, navItems, listClassName, itemClassName);
  }

  _remove() {
    this._authComponent.getElement().classList.remove(`popup--show`);
    document.querySelector(`body`).classList.remove(`overflowHidden`);
    document.removeEventListener(`keydown`, this._onEscPressModalHandler);
    this._onHideModalHanlder();
    setTimeout(() => remove(this._authComponent), 1000);
  }

  _onEscPressModalHandler(evt) {
    if (evt.key === `Escape`) {
      this._remove();
    }
  }
}
