import AbstractComponent from "./abstract-component";

const createTemplate = (phone, isDelay) => {
  return (
    `<div class="authorization__screen authorization__screen--login">
      <h2 class="authorization__title modal__title">Вход или регистрация</h2>

      <p class="authorization__text">Введите номер телефона, мы отправим SMS&#8209;сообщение с кодом подтверждения</p>

      <p class="authorization__input">
        <input class="js--phoneInput" name="phone" type="tel" inputmode="tel" placeholder="+7" value="${phone || `+7`}">
        <button class="authorization__clearButton js--clearButton" type="button" title="Очистить" data-show="${!!phone}">
          <svg aria-hidden="true" width="22" height="22">
            <use class="" href="sienge/img/sprite.svg#icon-clear"></use>
          </svg>
        </button>
      </p>
      
      <p class="authorization__errorMessage" data-show="false"></p>

      <div class="authorization__buttonWrap" data-mode="button" data-is-delay="${isDelay}">
        <button class="authorization__button authorization__button--setCode button-primary js--setAuthCodeButton" type="button">Ввести код</button>
        <button class="authorization__button authorization__button--getCode button-primary js--getAuthCodeButton" type="button">Получить код</button>

        <div class="modal__progress">
          <img src="/sienge/img/load_bar.gif" alt="">
        </div>
      </div>

      <button class="button-tertiary js--loginPasswordButton" type="button">Вход по паролю</button>
    </div>`
  );
};

export default class LoginScreenAuthModalComponent extends AbstractComponent {
  constructor({phone = ``, isDelay = false}) {
    super();

    this._phone = phone;
    this._isDelay = isDelay;
  }

  getTemplate() {
    return createTemplate(this._phone, this._isDelay);
  }

  setInProgress() {
    const wrapButtonElement = this.getElement().querySelector(`.authorization__buttonWrap`);
    const errorMessageElement = this.getElement().querySelector(`.authorization__errorMessage`);
    wrapButtonElement.dataset.mode = `progress`;
    errorMessageElement.dataset.show = `false`;
    errorMessageElement.textContent = ``;
  }

  showError(textError = ``) {
    const wrapButtonElement = this.getElement().querySelector(`.authorization__buttonWrap`);
    const errorMessageElement = this.getElement().querySelector(`.authorization__errorMessage`);
    errorMessageElement.dataset.show = `true`;
    errorMessageElement.textContent = textError;
    wrapButtonElement.dataset.mode = `button`;

    const wrap = this.getElement().querySelector(`.authorization__input`);
    wrap.classList.add(`shake`);

    this.setInputFocus();


    setTimeout(() => wrap.classList.remove(`shake`), 1000);
  }

  setIsDelay(data) {
    const wrapButtonElement = this.getElement().querySelector(`.authorization__buttonWrap`);
    wrapButtonElement.dataset.isDelay = data;
  }

  setGetAuthCodeButtonClickHandler(handler) {
    const button = this.getElement().querySelector(`.js--getAuthCodeButton`);

    if (!button) {
      return;
    }
    button.addEventListener(`click`, handler);
  }

  setSetAuthCodeButtonClickHandler(handler) {
    const button = this.getElement().querySelector(`.js--setAuthCodeButton`);

    if (!button) {
      return;
    }
    button.addEventListener(`click`, handler);
  }

  setPasswordButtonClickHandler(handler) {
    this.getElement().querySelector(`.js--loginPasswordButton`)
    .addEventListener(`click`, handler);
  }

  setInputFocus() {
    const input = this.getElement().querySelector(`.js--phoneInput`);
    input.focus();
    if (input && input.selectionStart !== null) {
      input.selectionStart = input.value.length;
    }
  }

  setClearButtonClickHandler(handler) {
    const clearButton = this.getElement().querySelector(`.js--clearButton`);
    const errorMessageElement = this.getElement().querySelector(`.authorization__errorMessage`);

    clearButton.addEventListener(`click`, () => {
      errorMessageElement.dataset.show = `false`;
      errorMessageElement.textContent = ``;

      this.getElement().querySelector(`.js--phoneInput`).value = ``;
      this.getElement().querySelector(`.js--phoneInput`).value = `+7`;
      clearButton.dataset.show = `false`;
      handler();
    });
  }


  setInputHandler(handler) {
    const clearButton = this.getElement().querySelector(`.js--clearButton`);
    const errorMessageElement = this.getElement().querySelector(`.authorization__errorMessage`);

    this.getElement().querySelector(`.js--phoneInput`)
    .addEventListener(`input`, (evt) => {
      errorMessageElement.dataset.show = `false`;
      errorMessageElement.textContent = ``;

      const inputElement = evt.target;
      const value = inputElement.value;
      clearButton.dataset.show = !!value;

      handler(value);
    });
  }

  setEnterKeydownInputHandler(handler) {
    this.getElement().querySelector(`.js--phoneInput`)
    .addEventListener(`keydown`, (evt) => {
      const isEnterKeyDown = evt.key === `Enter`;

      if (!isEnterKeyDown) {
        return;
      }

      if (!evt.target.value) {
        return;
      }

      handler();
    });
  }
}
