import AbstractComponent from "./abstract-component";

const createTemplate = (login) => {
  return (
    `<div class="authorization__screen authorization__screen--recoveryPassword">
      <div class="modal__titleWrap">
        <button class="modal__backButton" type="button">
          <svg aria-hidden="true" width="24" height="24">
            <use class="" href="sienge/img/sprite.svg#arrow-left"></use>
          </svg>
          Назад
        </button>
        <h2 class="authorization__title modal__title">Восстановление пароля</h2>
      </div>

      <div class="authorization__inputWrap">
        <p class="authorization__input input-placeholder">
          <input class="js--loginInput" type="text" placeholder=" " name="login2" id="login2" value="${login}">
          <label>Email или телефон</label>
          <button class="authorization__clearButton js--clearButton" type="button" title="Очистить" data-show="${!!login}">
            <svg aria-hidden="true" width="22" height="22">
              <use class="" href="sienge/img/sprite.svg#icon-clear"></use>
            </svg>
          </button>
        </p>
      </div>
      
      <p class="authorization__errorMessage" data-show="false"></p>
      
      <div class="authorization__buttonWrap" data-mode="button">
        <button class="authorization__button button-primary js--getPasswordButton" type="button">Напомнить</button>
        <div class="modal__progress">
          <img src="/sienge/img/load_bar.gif" alt="">
        </div>
      </div>
    </div>`
  );
};

export default class RecoveryPasswordScreenAuthModalComponent extends AbstractComponent {
  constructor({login = ``}) {
    super();

    this._login = login;
  }

  getTemplate() {
    return createTemplate(this._login);
  }

  setBackButtonClickHandler(handler) {
    this.getElement().querySelector(`.modal__backButton`)
    .addEventListener(`click`, handler);
  }

  setPasswordButtonClickHandler(handler) {
    this.getElement().querySelector(`.js--getPasswordButton`)
    .addEventListener(`click`, handler);
  }

  setClearButtonClickHandler(handler) {
    const clearButton = this.getElement().querySelector(`.js--clearButton`);

    clearButton.addEventListener(`click`, () => {
      this.getElement().querySelector(`.js--loginInput`).value = ``;
      clearButton.dataset.show = `false`;
      handler();
    });
  }

  setInputFocus() {
    const input = this.getElement().querySelector(`.js--loginInput`);

    input.focus();
    if (input && input.selectionStart !== null) {
      input.selectionStart = input.value.length;
    }
  }

  setInputHandler(handler) {
    const clearButton = this.getElement().querySelector(`.js--clearButton`);

    this.getElement().querySelector(`.js--loginInput`)
    .addEventListener(`input`, (evt) => {
      const inputElement = evt.target;
      const value = inputElement.value;
      clearButton.dataset.show = !!value;

      handler(value);
    });
  }

  setEnterKeydownInputHandler(handler) {
    this.getElement().querySelector(`.js--loginInput`)
    .addEventListener(`keydown`, (evt) => {
      const isEnterKeyDown = evt.key === `Enter`;

      if (!isEnterKeyDown) {
        return;
      }

      if (!evt.target.value) {
        return;
      }

      handler();
    });
  }
}
