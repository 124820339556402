import NavItemComponent from "./components/nav-item-component";
import UlComponent from "./components/ul";
import {RenderPosition, render} from "./utils/render";

export const renderNav = (container, items, listClassName, itemClassName) => {
  const listComponent = new UlComponent(listClassName);
  render(container, listComponent.getElement(), RenderPosition.BEFOREEND);

  items
  .forEach((item) => {
    const itemComponent = new NavItemComponent({
      className: itemClassName,
      title: item.title,
      link: item.link,
      svg: item.svg,
    });
    render(listComponent.getElement(), itemComponent.getElement(), RenderPosition.BEFOREEND);
  });
};
