import {getData} from "./api/get-data";

export const setCountCartHeader = (count) => {
  const cartHeaderLink = document.querySelector(`.js--cartHeaderLink`);
  if (!cartHeaderLink) {
    return;
  }
  cartHeaderLink.dataset.count = !count ? `` : count;
};

export const initCart = () => {
  const cartHeaderLink = document.querySelector(`.js--cartHeaderLink`);
  if (!cartHeaderLink) {
    return;
  }

  getData({
    [`get_header_cart`]: ``,
  })
  .then((response) => {
    if (response.result === `OK`) {
      setCountCartHeader(response.cart_count);
    }

    if (response.result === `ERROR`) {
      cartHeaderLink.dataset.count = ``;
    }
  });
};
