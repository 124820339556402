const headerElement = document.querySelector(`.main-header`);
const centerHeaderElement = headerElement.querySelector(`.main-header__center`);

export const onEscPressPopupHandler = (evt) => {
  if (evt.key === `Escape`) {
    const popupElements = document.querySelectorAll(`.popup--show`);

    popupElements.forEach((elem) => {
      elem.classList.remove(`popup--show`);
      centerHeaderElement.classList.remove(`main-header__center--popup`);
      document.removeEventListener(`keydown`, onEscPressPopupHandler);
      document.querySelector(`body`).classList.remove(`overflowHidden`);
    });
  }
};
