import AbstractComponent from "./abstract-component";

const createTemplate = (id, title, link) => {
  return (
    `<li class="social__item">
      <a class="social__link" href="${link}" title="${title}">
        <svg aria-hidden="true" width="22" height="22">
          <use class="" href="sienge/img/sprite.svg#icon-${id}"></use>
        </svg>
      </a>
    </li>`
  );
};

export default class SocialItemComponent extends AbstractComponent {
  constructor({id = ``, title = ``, link = ``}) {
    super();

    this._id = id;
    this._title = title;
    this._link = link;
  }

  getTemplate() {
    return createTemplate(this._id, this._title, this._link);
  }
}
