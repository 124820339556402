import {createAnchor} from "./create/create-ahchor";
import {createList} from "./create/create-list";
import {createListItem} from "./create/create-list-item";

export const renderBreadcrumbs = () => {
  const breadcrumbsElement = document.querySelector(`.breadcrumbs`);

  if (!breadcrumbsElement) {
    return;
  }

  if (!breadcrumbsData) {
    return;
  }

  const brearcrumbsListElement = createList(`breadcrumbs__list`);
  breadcrumbsElement.append(brearcrumbsListElement);

  Object.keys(breadcrumbsData)
  .forEach((key) => {
    const breadcrumb = breadcrumbsData[key];

    const liItemElement = createListItem(`breadcrumbs__item`);
    const anchorElement = createAnchor(`breadcrumbs__link`, breadcrumb.title || ``, breadcrumb.link || ``);

    liItemElement.append(anchorElement);
    brearcrumbsListElement.append(liItemElement);
  });
};

