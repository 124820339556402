import {getData} from "../api/get-data";

export const getAuthCode = (phone, resolveHandler = () => {}, rejectHanlder = () => {}) => {
  getData({
    [`get_auth_code`]: {
      [`phone`]: phone,
    }
  })
  .then((response) => {
    if (response.result === `OK`) {
      resolveHandler();
    }

    if (response.result === `ERROR`) {
      rejectHanlder(response.error);
    }
  })
  .catch(() => {
    rejectHanlder();
  });
};
