import {getFormattedPhone} from "../utils/get-formatted-phone";
import {getPhoneNumber} from "../utils/get-phone-number";
import AbstractComponent from "./abstract-component";

const createTemplate = (phone) => {
  const phoneText = getFormattedPhone(phone);
  const link = getPhoneNumber(phone);
  return (
    `<a class="contact__phone" href="${link}">
      <svg aria-hidden="true" width="18" height="18">
        <use class="" href="sienge/img/sprite.svg#icon-phone"></use>
      </svg>
      ${phoneText}
    </a>`
  );
};

export default class PhoneComponent extends AbstractComponent {
  constructor({phone}) {
    super();

    this._phone = phone;
  }

  getTemplate() {
    return createTemplate(this._phone);
  }
}
