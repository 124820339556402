import CityComponent from "./components/city-component";
import PhoneComponent from "./components/phone-component";
import {showCitiesPopup} from "./show-cities-popup";
import {RenderPosition, render} from "./utils/render";

const renderPhone = (container, phone = ``) => {
  if (!phone) {
    return;
  }

  const phoneComponent = new PhoneComponent({phone});

  render(container, phoneComponent.getElement(), RenderPosition.BEFOREEND);
};

const renderCity = (container, shopInfoData, logoData) => {
  if (!shopInfoData.select_town) {
    return;
  }

  const cityComponent = new CityComponent({city: shopInfoData.subdiv});
  cityComponent.setClickHanlder(() => showCitiesPopup(logoData));

  if (shopInfoData.require_town) {
    cityComponent.getElement().dataset.checked = `false`;
  }
  render(container, cityComponent.getElement(), RenderPosition.BEFOREEND);
};

export const renderContact = (container, shopInfoData, logoData) => {
  renderCity(container, shopInfoData, logoData);
  renderPhone(container, shopInfoData.phone);
};
