export const parseCitiesData = (shopListData, shopInfoData) => {
  return Object.keys(shopListData)
  .map((cityId) => {
    return {
      cityId,
      title: shopListData[cityId],
      isActive: shopListData[cityId] === shopInfoData.subdiv,
    };
  });
};
