import CitiesComponent from "../components/cities-component";
import CitiesModalComponent from "../components/cities-modal-component";
import {parseCitiesData} from "../data/parse-cities-data";
import {setCity} from "../require/set-city";
import {getAlphabetCities} from "../utils/get-alphabet-cities";
import {RenderPosition, remove, render} from "../utils/render";


export default class CitiesController {
  constructor({logoData}) {
    this._logoData = logoData;
    this._authComponent = null;

    this._onEscPressModalHandler = this._onEscPressModalHandler.bind(this);
  }

  show() {
    document.querySelector(`body`).classList.add(`overflowHidden`);
    document.addEventListener(`keydown`, this._onEscPressModalHandler);

    this._authComponent = new CitiesModalComponent({
      logoData: this._logoData,
    });
    render(document.querySelector(`body`), this._authComponent.getElement(), RenderPosition.BEFOREEND);

    const cities = parseCitiesData(shopListData, shopInfoData);
    this._renderCities(this._authComponent.getElement().querySelector(`.cities__list`), cities);

    this._authComponent.setCloseButtonClickHandler(() => this._remove());
    setTimeout(() => this._authComponent.setShowClass(), 100);
  }

  _renderCities(container, cities) {
    const alphabetCities = getAlphabetCities(cities);

    alphabetCities.forEach((alphabetCity) => {
      const component = new CitiesComponent({alphabetCity});
      render(container, component.getElement(), RenderPosition.BEFOREEND);
      component.getCityButtonClickHandler((cityId) => {
        setCity({cityId});
      });
    });
  }

  _remove() {
    this._authComponent.getElement().classList.remove(`popup--show`);
    document.querySelector(`body`).classList.remove(`overflowHidden`);
    document.removeEventListener(`keydown`, this._onEscPressModalHandler);
    setTimeout(() => remove(this._authComponent), 1000);
  }

  _onEscPressModalHandler(evt) {
    if (evt.key === `Escape`) {
      this._remove();
    }
  }
}
