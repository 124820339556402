import {Method} from "../const/method";

const loadRequest = ({url = `../../ajax.php`, method = Method.GET, body = null, credentials = `same-origin`, headers = new Headers(), signal = null}) => {
  return fetch(url, {method, body, credentials, headers, signal})
  .then((response) => checkStatus(response, body))
  .catch((err) => {
    throw err;
  });
};

const checkStatus = (response) => {
  if (response.status >= 200 && response.status < 300) {
    return response;
  }

  throw new Error(`${response.status}: ${response.statusText}`);
};


export const getData = (data) => {
  return loadRequest({
    method: Method.POST,
    body: JSON.stringify(data),
    headers: new Headers({'Content-Type': `application/x-www-form-urlencoded`})
  })
  .then((response) => response.json());
};
