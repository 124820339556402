import AbstractComponent from "./abstract-component";

const createTemplate = (isMobile, city) => {
  if (isMobile) {
    return (
      `<article class="contact__confirm contact__confirm--mobile">
        <button class="contact__city" type="button" data-checked="false" aria-hidden=""></button>
        <div class="contact__confirmWrapText">
          <p class="subtitle-text">Ваш город <b>${city}</b>?</p>
        </div>
  
        <div class="contact__confirmWrapButton">
          <button class="button-primary button-primary--small js--confirmCity" type="button">Да</button>
          <button class="button-secondary js--selectCity" type="button">Нет</button>
        </div>
      </article>`
    );
  }

  return (
    `<article class="contact__confirm">
      <div class="contact__confirmWrapText">
        <p class="subtitle-text">Ваш город <b>${city}</b>?</p>
        <p class="text">От выбора города зависят ассортимент, стоимость товара и сроки доставки</p>
      </div>

      <div class="contact__confirmWrapButton">
        <button class="button-secondary js--selectCity" type="button">Выбрать другой</button>
        <button class="button-primary js--confirmCity" type="button">Да, верно</button>
      </div>
    </article>`
  );
};

export default class CityConfirmComponent extends AbstractComponent {
  constructor({isMobile = true, city = ``}) {
    super();

    this._isMobile = isMobile;
    this._city = city;
  }

  getTemplate() {
    return createTemplate(this._isMobile, this._city);
  }

  setSelectCityButtonClickHandler(handler) {
    this.getElement().querySelector(`.js--selectCity`)
    .addEventListener(`click`, handler);
  }

  setConfirmCityButtonClickHandler(handler) {
    this.getElement().querySelector(`.js--confirmCity`)
    .addEventListener(`click`, handler);
  }
}
