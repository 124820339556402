import AbstractComponent from "./abstract-component";


const createTemplate = (catalogItem) => {
  const subitemMarkup = Object.keys(catalogItem.subgroup)
    .map((subitemId) => {
      const subitem = catalogItem.subgroup[subitemId];
      return (
        `<li class="catalog__subitem">
          <a class="catalog__link" href="${subitem.link}">${subitem.name}</a>
        </li>`
      );
    }).join(`\n`);

  return (
    `<li class="catalog__item">
      <a class="catalog__link" href="${catalogItem.link}">${catalogItem.name}</a>
      <div class="catalog__subwrap">
        <ul class="catalog__sublist">
          ${subitemMarkup}
        </ul>
      </div>
    </li>`
  );
};


export default class CatalogComponent extends AbstractComponent {
  constructor({catalogItem = {}}) {
    super();

    this._catalogItem = catalogItem;
  }

  getTemplate() {
    return createTemplate(this._catalogItem);
  }

  activate() {
    this.getElement().classList.add(`catalog__item--active`);
  }

  setMouseOverHandler(handler) {
    this.getElement().addEventListener(`mouseover`, handler);
  }
}
